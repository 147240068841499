'use client';

import clsx from 'clsx';
import { Form, useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import Button from '@/components/Button';
import Price from '@/components/Price';
import TextInput from '@/components/TextInput';
import { useTranslations } from 'next-intl';

import classes from './addDonationProductToCart.module.scss';

interface AddDonationFormProps {
    currency: string;
    donateButtonLabelLocalized: string;
    donations: number[];
    isAddDonationProductLoading: boolean;
    isMobileVersion?: boolean;
    isValidDonationAmount: (value: string) => string | undefined;
    withCurrencyMarks?: boolean;
}

const AddDonationForm = ({
    currency,
    donateButtonLabelLocalized,
    donations,
    isAddDonationProductLoading,
    isMobileVersion,
    isValidDonationAmount,
    withCurrencyMarks,
}: AddDonationFormProps) => {
    const t = useTranslations('donationProducts');
    const { setFieldValue, values } = useFormikContext<{ donationAmount: string }>();

    const handleChangeInputValue = useCallback((amount: number) => {
        setFieldValue('donationAmount', amount);
    }, []);

    return (
        <Form className={classes.form}>
            <div className={clsx(classes.controlWrapper, isMobileVersion && classes.mobileVersion)}>
                <div className={clsx(classes.list, isMobileVersion && classes.mobileVersion)}>
                    {donations.map((amount: number) => (
                        <Button
                            isFullWidth
                            type="button"
                            variant={'tertiary'}
                            key={`donation-${amount}`}
                            onClick={() => handleChangeInputValue(amount)}
                        >
                            {withCurrencyMarks ? (
                                <Price cutToDecimals currencyCode={currency} value={amount} />
                            ) : (
                                amount
                            )}
                        </Button>
                    ))}
                </div>
                <TextInput
                    classes={{ root: clsx(classes.input, isMobileVersion && classes.mobileVersion) }}
                    field="donationAmount"
                    id="donationAmount"
                    isRequired
                    label={values?.donationAmount ? t('yourDonation') : t('otherAmount')}
                    supplementLabel={currency}
                    isDonationInput
                    type={'tel'}
                    validate={isValidDonationAmount}
                />
            </div>
            <Button
                disabled={isAddDonationProductLoading || !values?.donationAmount}
                isFullWidth
                type="submit"
                variant={'tertiary'}
            >
                {donateButtonLabelLocalized}
            </Button>
        </Form>
    );
};

export default AddDonationForm;
