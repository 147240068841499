'use client';

import { useResourceUrl } from '@/hooks/useResourceUrl';
import { useHhRouter } from '@/utils/useHhRouter';

const useTakeMeHome = (): (() => void) => {
    const router = useHhRouter();
    const resourceUrl = useResourceUrl();

    return () => {
        router.push(resourceUrl('/'));
    };
};

export default useTakeMeHome;
