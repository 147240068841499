const home = '/home';

const urls = {
    aemBlog: ['/blog', '/blog/', '/blog.html', '/blog.html/'],
    aemRestPage: '/rest/hhecomm/page',
    aemRestRoutes: ['/headless_test', '/store-finder'],
    aemRestServices: '/rest/hhecomm/services/pages',
    aemWorkwearRestPage: '/rest/hhworkwear/page',
    aemWorkwearRestServices: '/rest/hhworkwear/services/pages',
    cart: '/checkout/cart',
    checkout: '/checkout',
    checkoutSuccess: '/checkout/onepage/success',
    createAccount: '/customer/account/create',
    customerAccount: '/customer/account/',
    customerCreatePassword: '/customer/account/forgotpassword',
    customerServiceCms: [
        '/customerservice',
        '/customer-service/contact/form',
        '/delivery',
        '/promotion-voucher',
        '/returns',
        '/size-chart',
        '/shopping-faq',
        '/technical-issues',
        '/ww-customerservice',
        '/ww-delivery',
        '/ww-promotion-voucher',
        '/ww-returns',
        '/ww-size-chart',
        '/ww-shopping-faq',
        '/ww-technical-issues',
    ],
    customerSignIn: '/customer/account/login',
    gcheckout: '/gcheckout',
    home,
    homes: [home, '/home', '/home/', '/home.html', '/home.html/'],
    orderHistory: '/sales/order/history',
    proInformation: '/customer/account/pro-information',
    resendConfirmationEmail: '/customer/account/confirm/resend',
    returns: '/returns',
    wishlists: ['/wishlist', '/wishlist/index/index/wishlist_id/:id'],
};

export default urls;
