'use client';

import React, { ReactElement, useEffect } from 'react';
import { useTranslations } from 'next-intl';
import { Formik } from 'formik';
import { FiChevronRight as ChevronRightIcon } from 'react-icons/fi';
import type { DonationProduct, Product } from '@vaimo/donation-products/src/types';
import {
    hasDonationProductsInCart,
    hasOnlyDonationProductsInCart,
    isAddDonationProductHidden,
    useDonationProducts,
} from '@vaimo/donation-products';
import RichContent from '@/components/RichContent';
import OptimizedImage from '@/components/OptimizedImage';
import Tooltip from '@/components/Tooltip';
import CollapsibleSSR from '@/components/CollapsibleSSR';
import AddDonationForm from './addDonationForm';

import clsx from 'clsx';
import classes from './addDonationProductToCart.module.scss';

interface AddDonationProductToCartProps {
    cartId: string;
    isMobileVersion?: boolean;
    productList: Product[] | null;
    withCurrencyMarks?: boolean;
}

const AddDonationProductToCart = ({
    cartId,
    isMobileVersion,
    productList,
    withCurrencyMarks,
}: AddDonationProductToCartProps): ReactElement | null => {
    const {
        currentCartTotal,
        donationProducts,
        donationRoundUpTo,
        donations,
        handleAddDonationToCart,
        handleDonationProductAutoRemoval,
        isAddDonationProductLoading,
        isValidDonationAmount,
        userInputDonationAmount,
    } = useDonationProducts({ cartId, hasDonationProductsInCart: hasDonationProductsInCart(productList) });
    const { currency } = currentCartTotal || {};

    useEffect(() => {
        if (!productList?.length || !hasOnlyDonationProductsInCart(productList)) {
            return;
        }

        handleDonationProductAutoRemoval(productList);
    }, [handleDonationProductAutoRemoval, productList]);

    const t = useTranslations('donationProducts');

    const donateButtonLabelLocalized = isAddDonationProductLoading ? t('addingDonationToCart') : t('donate');

    if (!donationProducts?.length || isAddDonationProductHidden(productList)) {
        return null;
    }

    return donationProducts.map((donationProduct: DonationProduct) => (
        <div
            className={clsx(classes.root, isMobileVersion && classes.mobileVersion)}
            key={donationProduct.sku}
            data-mt-type="donation-product-container"
        >
            <Tooltip
                content={<RichContent html={donationProduct.short_description?.html} />}
                classes={{
                    tooltipCollapsible: classes.donationProductTooltipWrapper,
                    tooltipTrigger: classes.donationProductTooltip,
                }}
            >
                {donationProduct.name}
            </Tooltip>
            <CollapsibleSSR
                className={classes.collapseOptionContainer}
                transitionTime={160}
                openedClassName={classes.collapseOptionContainerOpened}
                trigger={
                    <span className={classes.collapseOptionLabel}>
                        <ChevronRightIcon />
                    </span>
                }
            >
                <div
                    className={clsx(classes.content, isMobileVersion && classes.mobileVersion)}
                    data-mt-type="donation-product-content"
                >
                    <div className={classes.imageWrapper}>
                        <OptimizedImage
                            alt={donationProduct.name}
                            className={classes.image}
                            fill
                            src={donationProduct.small_image?.url}
                        />
                    </div>

                    {donationRoundUpTo && (
                        <Formik
                            initialValues={{
                                donationAmount: (userInputDonationAmount || '')?.toString(),
                            }}
                            onSubmit={(formValues) => {
                                handleAddDonationToCart({
                                    donationProduct,
                                    formValues,
                                });
                            }}
                        >
                            <AddDonationForm
                                isMobileVersion={isMobileVersion}
                                donations={donations}
                                withCurrencyMarks={withCurrencyMarks}
                                currency={currency}
                                donateButtonLabelLocalized={donateButtonLabelLocalized}
                                isValidDonationAmount={isValidDonationAmount}
                                isAddDonationProductLoading={isAddDonationProductLoading}
                            />
                        </Formik>
                    )}
                </div>
            </CollapsibleSSR>
        </div>
    ));
};

AddDonationProductToCart.displayName = 'DonationProduct';

export default AddDonationProductToCart;
